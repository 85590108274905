
import User from '@/models/user.model';
import Store from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
	props: ['users'],

	setup() {
		function getInitials(user: User | number) {
			if (typeof user == 'number') {
				user = Store.get('users').value.find((u: User) => u.id == user) as User;
			}

			if (!user.first_name || !user.last_name) {
				return '';
			}

			return `${user.first_name[0]}${user.last_name[0]}`;
		}

		function getFullName(user: User) {
			if (!user.first_name || !user.last_name) {
				return '';
			}

			return `${user.first_name} ${user.last_name}`;
		}

		return {
			// functions
			getInitials,
			getFullName,
		};
	},
});
