import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "grid place-items-center w-8 h-8" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, "-", 512), [
      [_vShow, !_ctx.users || !_ctx.users.length]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users?.slice(0, 3), (user) => {
        return (_openBlock(), _createElementBlock("div", {
          key: user,
          class: "relative z-10 -ml-2 text-xs font-bold w-8 h-8 flex justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue rounded-full border-2 border-white first:ml-0 first:z-20 last:z-0 transition",
          title: _ctx.getFullName(user)
        }, _toDisplayString(_ctx.getInitials(user)), 9, _hoisted_4))
      }), 128))
    ], 512), [
      [_vShow, _ctx.users && _ctx.users.length]
    ]),
    _withDirectives(_createElementVNode("div", { class: "ml-1 text-gray text-xs font-bold" }, "+" + _toDisplayString(_ctx.users?.length - 3), 513), [
      [_vShow, _ctx.users?.length > 3]
    ])
  ]))
}