
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import _sortBy from 'lodash/sortBy';
import Store from '@/store';

import MeetingService from '@/services/meeting.service';

import Layout from '@/components/Layout.vue';
import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';
import UserList from '@/components/UserList.vue';
import MeetingOverlay from '@/components/meeting/Overlay.vue';
import AddMeetingOverlay from '@/components/meeting/AddOverlay.vue';
import useEmitter from '@/compostables/useEmitter';
import Meeting from '@/models/meeting.model';

export default defineComponent({
	components: {
		Layout,
		SVG,
		Loader,
		Table,
		TableRow,
		UserList,
		MeetingOverlay,
		AddMeetingOverlay,
	},

	setup() {
		const emitter = useEmitter();
		const route = useRoute();
		const user = Store.get('user');

		const meetings = Store.get('meetings');

		const input = ref(null as HTMLInputElement | null);

		const loading = ref(true);

		const showOlder = ref(false);

		const overlay = reactive({
			active: false,
			addActive: false,
		});

		const upcomingMeetings = computed(() => {
			let meetings = Store.get('meetings').value;

			// Sort the Meetings
			meetings = _sortBy(meetings, ['scheduled_at', 'id']).reverse();

			meetings = meetings.filter(
				(meeting: Meeting) => !meeting.scheduled_at || new Date(meeting.scheduled_at ?? 0) > new Date()
			);

			return meetings;
		});

		const previousMeetings = computed(() => {
			let meetings = Store.get('meetings').value;

			meetings = meetings.filter(
				(meeting: Meeting) => meeting.scheduled_at && new Date(meeting.scheduled_at ?? 0) < new Date()
			);

			if (!showOlder.value) {
				meetings = meetings.filter(
					(meeting: Meeting) =>
						new Date(meeting.scheduled_at ?? 0).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 90
				);
			}

			// Sort the Meetings
			meetings.sort((a: Meeting, b: Meeting) => {
				// if both Meetings have scheduled_at, order by scheduled_at
				return new Date(b.scheduled_at).getTime() - new Date(a.scheduled_at).getTime();
			});

			return meetings;
		});

		const hasOld = computed(() => {
			const meetings = Store.get('meetings').value;

			return !!meetings.filter(
				(meeting: Meeting) => meeting.scheduled_at && new Date(meeting.scheduled_at ?? 0).getTime() < Date.now()
			).length;
		});

		const hasOlder = computed(() => {
			const meetings = Store.get('meetings').value;

			return !!meetings.filter(
				(meeting: Meeting) =>
					meeting.scheduled_at &&
					new Date(meeting.scheduled_at ?? 0).getTime() < Date.now() - 1000 * 60 * 60 * 24 * 90
			).length;
		});

		onMounted(async () => {
			// close the overlay when escape is pressed
			emitter.on('escape', () => {
				overlay.active = false;
				overlay.addActive = false;
			});

			Store.set('header.nav', [
				{
					title: 'Listi',
					name: 'Meetings',
				},
				{
					title: 'Ársyvirlit',
					name: 'MeetingCards',
				},
			]);

			Store.set('header.options', [
				{
					title: 'Stovna fund',
					action: 'task:add',
				},
			]);

			// Open Task overlay
			emitter.on('task:add', () => {
				Store.set('meeting', null);
				overlay.addActive = true;

				const input: HTMLInputElement | null = document.querySelector('.meeting-input');

				if (input) {
					input.focus();
				}
			});

			// Get the Meetings
			await MeetingService.get()
				.then((meetings) => {
					if (meetings) {
						Store.set('meetings', meetings);
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		watch(meetings, (meetingsValue) => {
			if (route.query?.meeting_id) {
				const meeting = meetingsValue.filter(
					(meeting: Meeting) => meeting.id === Number(route.query.meeting_id)
				);

				openOverlay(meeting[0]);

				window.history.pushState({}, document.title, window.location.pathname);
			}
		});

		const newMeeting = ref('');
		const isAdding = ref(false);

		// Add a Meeting
		async function addMeeting() {
			const title = newMeeting.value;

			isAdding.value = false;
			newMeeting.value = '';

			if (!title) {
				return;
			}

			await MeetingService.create({
				title,
			}).then((response) => {
				if (response) {
					Store.set('meetings', [...meetings.value, response]);
				}
			});
		}

		/**
		 * Open the Meeting Overlay
		 */
		function openOverlay(meeting: Meeting) {
			if (user.value.cannot('update meetings')) {
				return;
			}
			Store.set('meeting', meeting);

			overlay.active = true;
		}

		/**
		 * Whether the meeting has already taken place or not
		 */
		function hasTakenPlace(meeting: Meeting) {
			if (!meeting.scheduled_at) {
				return false;
			}

			return new Date() > new Date(meeting.scheduled_at ?? 0);
		}

		/**
		 * Remove a Meeting
		 */
		async function remove(meetingId: number) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await MeetingService.delete(meetingId).then((response) => {
				if (response) {
					Store.set(
						'meetings',
						meetings.value.filter((meeting: Meeting) => meeting.id != meetingId)
					);
				}
			});
		}

		return {
			// data
			input,
			meetings,
			overlay,
			loading,
			newMeeting,
			isAdding,
			showOlder,
			user,

			// computed
			upcomingMeetings,
			previousMeetings,
			hasOld,
			hasOlder,

			// functions
			addMeeting,
			openOverlay,
			hasTakenPlace,
			remove,
		};
	},
});
